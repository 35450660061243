import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Col, Row } from 'react-bootstrap';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function Chart(props) {
  let [winPercent, setWinPercent] = useState(0);
  let [win, setWin] = useState(0);
  let [lose, setLose] = useState(0);
  let [draw, setDraw] = useState(0);
  let [total, setTotal] = useState(0);

  useEffect(() => {
    setWin(0);
    setLose(0);
    setDraw(0);
    if (props.matchList?.length > 0) {
      let tempWin = 0;
      let tempLose = 0;
      let tempDraw = 0;

      props.matchList.forEach((match) => {
        match.matchInfo.forEach((matchInfo) => {
          if (matchInfo.ouid === props.ouid) {
            const result = matchInfo.matchDetail.matchResult;
            if (result === '승') {
              tempWin++;
            } else if (result === '패') {
              tempLose++;
            } else if (result === '무') {
              tempDraw++;
            }
          }
        });
      });

      // 최종적으로 상태 업데이트
      setWin(tempWin);
      setLose(tempLose);
      setDraw(tempDraw);

      const total = tempWin + tempLose + tempDraw;
      if (total > 0) {
        setWinPercent(((tempWin / total) * 100).toFixed(1));
      } else {
        setWinPercent(0);
      }
      setTotal(total);
    }

    setWinPercent(((win / total) * 100).toFixed(1));
  }, [props.matchList, props.ouid]);

  const data = {
    labels: ['승리', '패배', '무승부'],
    datasets: [
      {
        data: [win, lose, draw],
        backgroundColor: ['dodgerblue', 'lightcoral', 'lightsteelblue'],
        borderWidth: 1,
      },
    ],
  };

  const options = {};

  return (
    <div className="chart">
      <Row>
        <div>
          <h4 style={{ fontSize: '25px', fontWeight: 'bold' }}>
            {total} 경기 승률
          </h4>
        </div>
      </Row>
      <Row className="statistics">
        <Col>
          <div style={{ width: '150px', height: '150px', margin: '0 auto' }}>
            <Doughnut data={data} options={options} />
          </div>
        </Col>
        <Col>
          <div style={{ fontSize: '15px', fontWeight: 'bold' }}>
            {total}전 {win}승 {lose}패 {draw}무
          </div>
          <div>
            승률 :{' '}
            <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
              {total === 0 ? 0 : winPercent}%
            </span>
          </div>
        </Col>
      </Row>
    </div>
  );
}
