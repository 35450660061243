import { Container } from 'react-bootstrap';
import Text from '../components/Text';
import { post } from '../assets/ajax.js';
import { useEffect, useState } from 'react';

function Home() {
  useEffect(() => {
    let addVisitcount = async () => {
      let extraUrl = '/visitcount';
      await post({ extraUrl });
    };

    addVisitcount();
  }, []);

  return (
    <div
      style={{
        display: 'flex', // Flexbox 활성화
        alignItems: 'center', // 세로 중앙 정렬
        justifyContent: 'center', // 가로 중앙 정렬
        height: '90vh', // 부모 요소가 화면 높이 전체를 차지
      }}
    >
      <Container
        style={{
          height: '75vh',
        }}
      >
        <div>
          <img alt="home" src="./img/home.png" className="centered-img" />
        </div>
        <div
          style={{
            paddingTop: '20px',
          }}
        ></div>
        <Text />
      </Container>
    </div>
  );
}

export default Home;
