import { useEffect, useState } from 'react';
import spid from '../assets/spid.json';
import { Col, Row } from 'react-bootstrap';
import { getImg } from '../assets/ajax';

export function TopGoal(props) {
  let [topGoalPlayer, setTotalGoalPlayer] = useState([]);
  const [playerImages, setPlayerImages] = useState({});
  let [total, setTotal] = useState(0);

  // JSON 파일을 읽어옵니다
  const jsonData = spid;

  // 'id'를 키로, 'name'을 값으로 갖는 객체로 변환
  const PlayersEnum = Object.freeze(
    jsonData.reduce((acc, player) => {
      acc[player.id] = player.name;
      return acc;
    }, {})
  );

  // matchList가 변경될 때마다 최다 득점자 계산
  useEffect(() => {
    if (props.matchList.length > 0) {
      setTotal(props.matchList.length);
      let tempPlayer = [];
      props.matchList.forEach((match) => {
        match.matchInfo.forEach((matchInfo) => {
          if (matchInfo.ouid === props.ouid) {
            matchInfo.player.forEach((player) => {
              tempPlayer.push(player);
            });
          }
        });
      });

      // 중복 없이 출전 선수 spId 추출
      const spIdArray = [...new Set(tempPlayer.map((player) => player.spId))];

      // 각 spId별로 골 수를 합산한 객체 생성
      const goalStats = spIdArray.map((spId) => {
        const totalGoals = tempPlayer
          .filter((player) => player.spId === spId)
          .reduce((sum, player) => sum + player.status.goal, 0);

        return {
          spId,
          totalGoals,
        };
      });

      // 총 득점자를 정렬 (골 수 기준 내림차순)
      goalStats.sort((a, b) => b.totalGoals - a.totalGoals);

      // 상위 3명의 득점자만 추출
      setTotalGoalPlayer(goalStats.slice(0, 3));
    }
  }, [props.matchList]);

  // 선수 이미지 비동기적으로 가져오기
  useEffect(() => {
    const fetchPlayerImages = async () => {
      const imagePromises = topGoalPlayer.map(async (player) => {
        const imgUrl = await getNetworkImg(
          // parseInt(player.spId.toString().slice(-6), 10)
          parseInt(player.spId.toString(), 10)
        );
        setPlayerImages((prev) => ({
          ...prev,
          [player.spId]: imgUrl,
        }));
      });

      await Promise.all(imagePromises);
    };

    if (topGoalPlayer.length > 0) {
      fetchPlayerImages();
    }
  }, [topGoalPlayer]);

  return (
    <div>
      <div>
        <h4 style={{ fontSize: '20px', fontWeight: 'bold' }}>
          {total} 경기 최다득점자
        </h4>
      </div>
      <div>
        {topGoalPlayer.map((player, i) => (
          <Row
            className="align-items-center"
            style={{ marginBottom: '10px' }}
            key={player.spId}
          >
            <Col xs="auto">
              {/* 이미지 출력 */}
              <img
                className="player-image"
                src={playerImages[player.spId]}
                style={{
                  border: `3px solid ${props.borderColor}`,
                  width: '50px', // 이미지 크기 설정
                  height: '50px', // 이미지 크기 설정
                  borderRadius: '50%', // 둥근 모양으로
                }}
                onError={(e) => (e.target.src = './img/man.png')} // 이미지 오류시 기본 이미지
              />
            </Col>
            <Col xs="auto" style={{ fontSize: '20px', fontWeight: 'bold' }}>
              {i + 1}. {PlayersEnum[player.spId]}
            </Col>
            <Col xs="auto">
              총 득점 :{' '}
              <span
                style={{ color: 'blue', fontWeight: 'bold', fontSize: '20px' }}
              >
                {player.totalGoals}
              </span>
            </Col>
          </Row>
        ))}
      </div>
    </div>
  );
}

// 네트워크로 이미지를 가져오는 함수
async function getNetworkImg(spId) {
  let extraUrl = `/meta/img/${spId}`;
  let result = await getImg({ extraUrl });
  return result;
}
