export function Utc9(props) {
  // UTC 시간 (2024-02-16T21:28:23)
  let utcDate = new Date(props.date);

  // UTC+9 (한국 시간)으로 변환
  let koreanTime = new Date(utcDate.getTime() + 9 * 60 * 60 * 1000);

  let formattedDate = koreanTime.toISOString().split('.')[0].replace('T', ' ');

  return <span>{formattedDate}</span>;
}
