export class ReqMatchSearchFilter {
  ouid;
  matchtype;
  offset;

  constructor(ouid, matchtype, offset) {
    this.ouid = ouid;
    this.matchtype = matchtype;
    this.offset = offset;
  }
}
