export const MatchType = {
  30: '리그 친선',
  40: '클래식 1on1',
  50: '공식경기',
  52: '감독모드',
  60: '공식 친선',
  204: '볼타 친선',
  214: '볼타 공식',
  224: '볼타 AI대전',
  234: '볼타 커스텀',
};

export const Division = {
  800: '슈퍼챔피언스',
  900: '챔피언스',
  1000: '슈퍼챌린지',
  1100: '챌린지1',
  1200: '챌린지2',
  1300: '챌린지3',
  2000: '월드클래스1',
  2100: '월드클래스2',
  2200: '월드클래스3',
  2300: '프로1',
  2400: '프로2',
  2500: '프로3',
  2600: '세미프로1',
  2700: '세미프로2',
  2800: '세미프로3',
  2900: '유망주1',
  3000: '유망주2',
  3100: '유망주3',
  0: 'Unranked',
};

export const DivisionIcon = {
  800: './img/rank/superchampions.png',
  900: './img/rank/champions.png',
  1000: './img/rank/superchallenger.png',
  1100: './img/rank/challenger1.png',
  1200: './img/rank/challenger2.png',
  1300: './img/rank/challenger3.png',
  2000: './img/rank/worldclass1.png',
  2100: './img/rank/worldclass2.png',
  2200: './img/rank/worldclass3.png',
  2300: './img/rank/pro1.png',
  2400: './img/rank/pro2.png',
  2500: './img/rank/pro3.png',
  2600: './img/rank/semipro1.png',
  2700: './img/rank/semipro2.png',
  2800: './img/rank/semipro3.png',
  2900: './img/rank/rookie1.png',
  3000: './img/rank/rookie2.png',
  3100: './img/rank/rookie3.png',
  0: './img/rank/unrank.png',
};

export const DivisionVolta = {
  1100: '월드 스타',
  2000: '내셔널 스타1부',
  2100: '내셔널 스타2부',
  2200: '내셔널 스타3부',
  2300: '라이징 스타1부',
  2400: '라이징 스타2부',
  2500: '라이징 스타3부',
  2600: '슈퍼 루키 1부',
  2700: '슈퍼 루키 2부',
  2800: '슈퍼 루키 3부',
  2900: '루키 1부',
  3000: '루키 2부',
  3100: '루키 3부',
  0: 'Unranked',
};

export const DivisionVoltaIcon = {
  1100: './img/rank/volta/worldstar.png',
  2000: './img/rank/volta/nationalstar1.png',
  2100: './img/rank/volta/nationalstar2.png',
  2200: './img/rank/volta/nationalstar3.png',
  2300: './img/rank/volta/risingstar1.png',
  2400: './img/rank/volta/risingstar2.png',
  2500: './img/rank/volta/risingstar3.png',
  2600: './img/rank/volta/superrookie1.png',
  2700: './img/rank/volta/superrookie2.png',
  2800: './img/rank/volta/superrookie3.png',
  2900: './img/rank/volta/rookie1.png',
  3000: './img/rank/volta/rookie2.png',
  3100: './img/rank/volta/rookie3.png',
  0: './img/rank/unrank.png',
};

export const HomePosition = {
  0: { desc: 'GK', top: '45%', left: '0.5%', borderColor: 'gold' },
  1: { desc: 'SW', top: '45%', left: '12%', borderColor: 'blue' },
  2: { desc: 'RWB', top: '85%', left: '17%', borderColor: 'blue' },
  3: { desc: 'RB', top: '85%', left: '8.5%', borderColor: 'blue' },
  4: { desc: 'RCB', top: '65%', left: '8.5%', borderColor: 'blue' },
  5: { desc: 'CB', top: '45%', left: '8.5%', borderColor: 'blue' },
  6: { desc: 'LCB', top: '26%', left: '8.5%', borderColor: 'blue' },
  7: { desc: 'LB', top: '5%', left: '8.5%', borderColor: 'blue' },
  8: { desc: 'LWB', top: '5%', left: '17%', borderColor: 'blue' },
  9: { desc: 'RDM', top: '65%', left: '17%', borderColor: 'green' },
  10: { desc: 'CDM', top: '45%', left: '17%', borderColor: 'green' },
  11: { desc: 'LDM', top: '26%', left: '17%', borderColor: 'green' },
  12: { desc: 'RM', top: '85%', left: '25.3%', borderColor: 'green' },
  13: { desc: 'RCM', top: '60%', left: '25.3%', borderColor: 'green' },
  14: { desc: 'CM', top: '45%', left: '25.3%', borderColor: 'green' },
  15: { desc: 'LCM', top: '30%', left: '25.3%', borderColor: 'green' },
  16: { desc: 'LM', top: '5%', left: '25.3%', borderColor: 'green' },
  17: { desc: 'RAM', top: '60.5%', left: '31%', borderColor: 'green' },
  18: { desc: 'CAM', top: '45%', left: '31%', borderColor: 'green' },
  19: { desc: 'LAM', top: '29.5%', left: '31%', borderColor: 'green' },
  20: { desc: 'RF', top: '69%', left: '31%', borderColor: 'red' },
  21: { desc: 'CF', top: '45%', left: '33.5%', borderColor: 'red' },
  22: { desc: 'LF', top: '21%', left: '31%', borderColor: 'red' },
  23: { desc: 'RW', top: '85%', left: '40%', borderColor: 'red' },
  24: { desc: 'RS', top: '69%', left: '40%', borderColor: 'red' },
  25: { desc: 'ST', top: '45%', left: '41.8%', borderColor: 'red' },
  26: { desc: 'LS', top: '21%', left: '40%', borderColor: 'red' },
  27: { desc: 'LW', top: '5%', left: '40%', borderColor: 'red' },
  28: { desc: 'SUB', top: '0%', left: '0%', borderColor: 'gray' },
};

export const AwayPosition = {
  0: { desc: 'GK', top: '45%', left: '92%', borderColor: 'gold' },
  1: { desc: 'SW', top: '45%', left: '80.5%', borderColor: 'blue' },
  2: { desc: 'LWB', top: '85%', left: '75.5%', borderColor: 'blue' },
  3: { desc: 'LB', top: '85%', left: '84%', borderColor: 'blue' },
  4: { desc: 'LCB', top: '65%', left: '84%', borderColor: 'blue' },
  5: { desc: 'CB', top: '45%', left: '84%', borderColor: 'blue' },
  6: { desc: 'RCB', top: '26%', left: '84%', borderColor: 'blue' },
  7: { desc: 'RB', top: '5%', left: '84%', borderColor: 'blue' },
  8: { desc: 'RWB', top: '5%', left: '75.5%', borderColor: 'blue' },
  9: { desc: 'LDM', top: '65%', left: '75.5%', borderColor: 'green' },
  10: { desc: 'CDM', top: '45%', left: '75.5%', borderColor: 'green' },
  11: { desc: 'RDM', top: '26%', left: '75.5%', borderColor: 'green' },
  12: { desc: 'LM', top: '85%', left: '67.2%', borderColor: 'green' },
  13: { desc: 'LCM', top: '60%', left: '67.2%', borderColor: 'green' },
  14: { desc: 'CM', top: '45%', left: '67.2%', borderColor: 'green' },
  15: { desc: 'RCM', top: '30%', left: '67.2%', borderColor: 'green' },
  16: { desc: 'RM', top: '5%', left: '67.2%', borderColor: 'green' },
  17: { desc: 'LAM', top: '60.5%', left: '59%', borderColor: 'green' },
  18: { desc: 'CAM', top: '45%', left: '59%', borderColor: 'green' },
  19: { desc: 'RAM', top: '29.5%', left: '59%', borderColor: 'green' },
  20: { desc: 'LF', top: '69%', left: '60%', borderColor: 'red' },
  21: { desc: 'CF', top: '45%', left: '58%', borderColor: 'red' },
  22: { desc: 'RF', top: '21%', left: '60%', borderColor: 'red' },
  23: { desc: 'LW', top: '85%', left: '52.5%', borderColor: 'red' },
  24: { desc: 'LS', top: '69%', left: '52.5%', borderColor: 'red' },
  25: { desc: 'ST', top: '45%', left: '50.7%', borderColor: 'red' },
  26: { desc: 'RS', top: '21%', left: '52.5%', borderColor: 'red' },
  27: { desc: 'RW', top: '5%', left: '52.5%', borderColor: 'red' },
  28: { desc: 'SUB', top: '0%', left: '0%', borderColor: 'gray' },
};
