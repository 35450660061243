import {
  DivisionIcon,
  Division,
  MatchType,
  DivisionVolta,
  DivisionVoltaIcon,
} from '../assets/enum/enum';
import { Utc9 } from './Utc9';

export function MaxRankCard({ maxrank }) {
  return (
    <div className="max-rank">
      <div style={{ marginBlockEnd: '10px' }}>
        <img
          src={
            maxrank.matchType !== 214
              ? DivisionIcon[maxrank.division]
              : DivisionVoltaIcon[maxrank.division]
          }
          style={{
            width: '80px',
          }}
        />
      </div>
      <div>
        <h5 style={{ fontWeight: 'bold' }}>{MatchType[maxrank.matchType]}</h5>
      </div>
      <div>
        <span style={{ fontWeight: 'bold' }}>
          {' '}
          최고등급 :{' '}
          {maxrank.matchType !== 214
            ? Division[maxrank.division]
            : DivisionVolta[maxrank.division]}
        </span>
      </div>
      <div>
        {maxrank.achievementDate !== null ? (
          <span>
            등급 달성 일시 : <Utc9 date={maxrank.achievementDate} />
          </span>
        ) : (
          <span>등급 달성 일시 : -</span>
        )}
      </div>
    </div>
  );
}
