import { useEffect, useState } from 'react';

export function Shoot(props) {
  let [totalGoal, setTotalGoal] = useState(0);
  let [losePoint, setLosePoint] = useState(0);
  let [avgGoal, setAvgTotalGoal] = useState(0);
  let [avgLosePoint, setAvgLosePoint] = useState(0);
  let [total, setTotal] = useState(0);

  useEffect(() => {
    let totalGame = props.matchList.length;
    if (props.matchList?.length > 0) {
      let tempGoal = 0;
      let tempLosePoint = 0;

      props.matchList.forEach((match) => {
        match.matchInfo.forEach((matchInfo) => {
          if (matchInfo.ouid === props.ouid) {
            tempGoal += matchInfo.shoot.goalTotalDisplay;
          } else {
            tempLosePoint += matchInfo.shoot.goalTotalDisplay;
          }
        });
      });

      // 최종적으로 상태 업데이트
      setTotalGoal(tempGoal);
      setLosePoint(tempLosePoint);

      if (total > 0) {
        setAvgTotalGoal((tempGoal / total).toFixed(1));
        setAvgLosePoint((tempLosePoint / total).toFixed(1));
      }
    }

    setTotal(totalGame);
  }, [props.matchList, props.ouid]);

  return (
    <div>
      <div>
        <h4 style={{ fontSize: '25px', fontWeight: 'bold' }}>{total} 경기</h4>
      </div>
      <div className="shoot">
        총 득점 : <span style={{ color: 'blue' }}> {totalGoal}</span> 골
      </div>
      <div className="shoot">
        총 실점 :<span style={{ color: 'red' }}> {losePoint}</span> 실점
      </div>
      <div className="shoot">
        평균 득점 :<span style={{ color: 'blue' }}> {avgGoal}</span> 골
      </div>
      <div className="shoot">
        평균 실점 : <span style={{ color: 'red' }}> {avgLosePoint}</span> 실점
      </div>
    </div>
  );
}
