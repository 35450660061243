import React, { useState, useEffect } from 'react';
import { HomePosition, AwayPosition } from '../assets/enum/enum';
import spid from '../assets/spid.json';
import { PlayerIcon } from './PlayerIcon';
import { getImg } from '../assets/ajax';

export function Map(props) {
  const [playerImages, setPlayerImages] = useState({});

  // JSON 파일을 읽어옵니다
  const jsonData = spid;

  // 'id'를 키로, 'name'을 값으로 갖는 객체로 변환
  const PlayersEnum = Object.freeze(
    jsonData.reduce((acc, player) => {
      acc[player.id] = player.name;
      return acc;
    }, {})
  );

  const match = props.match.matchInfo;

  useEffect(() => {
    // 각 선수의 이미지를 비동기적으로 가져옵니다
    const fetchPlayerImages = async () => {
      const imagePromises = match.flatMap((matchInfo) =>
        matchInfo.player.map(async (player) => {
          if (player.spPosition !== 28) {
            const imgUrl = await getNetworkImg(
              // parseInt(player.spId.toString().slice(-6), 10).toString()
              parseInt(player.spId.toString(), 10).toString()
            );
            setPlayerImages((prev) => ({
              ...prev,
              [player.spId]: imgUrl,
            }));
          }
        })
      );

      await Promise.all(imagePromises);
    };

    fetchPlayerImages();
  }, [match]);

  return (
    <div className="map-container">
      {match.map((matchInfo, i) => {
        return i === 0
          ? matchInfo.player.map((player) =>
              player.spPosition !== 28 ? (
                <PlayerIcon
                  key={player.spId}
                  top={HomePosition[player.spPosition].top}
                  left={HomePosition[player.spPosition].left}
                  src={playerImages[player.spId] || './img/man.png'}
                  position={HomePosition[player.spPosition].desc}
                  name={
                    PlayersEnum[player.spId] != null
                      ? PlayersEnum[player.spId].split(' ').pop()
                      : null
                  }
                  borderColor={HomePosition[player.spPosition].borderColor}
                  spRating={player.status.spRating}
                  player={player}
                />
              ) : null
            )
          : matchInfo.player.map((player) =>
              player.spPosition !== 28 ? (
                <PlayerIcon
                  key={player.spId}
                  top={AwayPosition[player.spPosition].top}
                  left={AwayPosition[player.spPosition].left}
                  src={playerImages[player.spId] || ''} // 이미지 URL이 없으면 빈 문자열
                  position={AwayPosition[player.spPosition].desc}
                  name={
                    PlayersEnum[player.spId] != null
                      ? PlayersEnum[player.spId].split(' ').pop()
                      : null
                  }
                  borderColor={AwayPosition[player.spPosition].borderColor}
                  spRating={player.status.spRating}
                  player={player}
                />
              ) : null
            );
      })}
    </div>
  );
}

async function getNetworkImg(spId) {
  let extraUrl = `/meta/img/${spId}`;
  let result = await getImg({ extraUrl });

  return result;
}
