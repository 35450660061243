export class Info {
  level;
  ouid;
  nickname;
  maxRank = [];

  constructor(data) {
    this.level = data.level;
    this.ouid = data.ouid;
    this.nickname = data.nickname;
    this.maxRank = data.maxRank?.map((rank) => new MaxRank(rank)) || []; // 배열을 MaxRank 객체로 변환
  }
}

export default class MaxRank {
  achievementDate;
  division;
  matchType;

  constructor(data) {
    this.matchType = data.matchType;
    this.division = data.division;
    this.achievementDate = data.achievementDate;
  }
}
