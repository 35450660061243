export class Match {
  matchId;
  matchDate;
  matchType;
  matchInfo = [];

  constructor(data) {
    this.matchId = data.matchId;
    this.matchDate = data.matchDate;
    this.matchType = data.matchType;
    this.matchInfo = data.matchInfo?.map((info) => new MatchInfo(info)) || [];
  }
}

class MatchInfo {
  ouid;
  nickname;
  matchDetail;
  shoot;
  shootDetail = [];
  pass;
  defence;
  player = [];

  constructor(data) {
    this.ouid = data.ouid;
    this.nickname = data.nickname;
    this.matchDetail = data.matchDetail
      ? new MatchDetail(data.matchDetail)
      : null;
    this.shoot = data.shoot ? new Shoot(data.shoot) : null;
    this.shootDetail =
      data.shootDetail?.map((detail) => new ShootDetail(detail)) || [];
    this.pass = data.pass ? new Pass(data.pass) : null;
    this.defence = data.defence ? new Defence(data.defence) : null;
    this.player = data.player?.map((player) => new Player(player)) || [];
  }
}

class MatchDetail {
  seasonId;
  matchResult;
  matchEndType;
  systemPause;
  foul;
  injury;
  redCards;
  yellowCards;
  dribble;
  cornerKick;
  possession;
  offsideCount;
  averageRating;
  controller;

  constructor(data) {
    this.seasonId = data.seasonId;
    this.matchResult = data.matchResult;
    this.matchEndType = data.matchEndType;
    this.systemPause = data.systemPause;
    this.foul = data.foul;
    this.injury = data.injury;
    this.redCards = data.redCards;
    this.yellowCards = data.yellowCards;
    this.dribble = data.dribble;
    this.cornerKick = data.cornerKick;
    this.possession = data.possession;
    this.offsideCount = data.OffsideCount; // 키 이름이 CamelCase와 일치하도록 수정
    this.averageRating = data.averageRating;
    this.controller = data.controller;
  }
}

class Shoot {
  shootTotal;
  effectiveShootTotal;
  shootOutScore;
  goalTotal;
  goalTotalDisplay;
  ownGoal;
  shootHeading;
  goalHeading;
  shootFreekick;
  goalFreekick;
  shootInPenalty;
  goalInPenalty;
  shootOutPenalty;
  goalOutPenalty;
  shootPenaltyKick;
  goalPenaltyKick;

  constructor(data) {
    Object.assign(this, data);
  }
}

class ShootDetail {
  goalTime;
  x;
  y;
  type;
  result;
  spId;
  spGrade;
  spLevel;
  spIdType;
  assist;
  assistSpi;
  assistX;
  assistY;
  hitPost;
  inPenalty;

  constructor(data) {
    Object.assign(this, data);
  }
}

class Pass {
  passTry;
  passSuccess;
  shortPassTry;
  shortPassSuccess;
  longPassTry;
  longPassSuccess;
  bouncingLobPassTry;
  bouncingLobPassSuccess;
  drivenGroundPassTry;
  drivenGroundPassSuccess;
  throughPassTry;
  throughPassSuccess;
  lobbedThroughPassTry;
  lobbedThroughPassSuccess;

  constructor(data) {
    Object.assign(this, data);
  }
}

class Defence {
  blockTry;
  blockSuccess;
  tackleTry;
  tackleSuccess;

  constructor(data) {
    Object.assign(this, data);
  }
}

class Player {
  spId;
  spPosition;
  spGrade;
  tackleSuccess;
  status;
  bestSpRating;

  constructor(data) {
    this.spId = data.spId;
    this.spPosition = data.spPosition;
    this.spGrade = data.spGrade;
    this.tackleSuccess = data.tackleSuccess;
    this.status = data.status ? new Status(data.status) : null;
  }
}

class Status {
  shoot;
  effectiveShoot;
  goal;
  dribble;
  intercept;
  defending;
  passTry;
  passSuccess;
  dribbleTry;
  dribbleSuccess;
  ballPossesionTry;
  ballPossesionSuc;
  aerialTry;
  aerialSuccess;
  blockTry;
  block;
  tackleTry;
  tackle;
  yellowCards;
  redCards;
  spRating;

  constructor(data) {
    Object.assign(this, data);
  }
}
