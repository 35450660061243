import { useEffect, useState } from 'react';
import { Utc9 } from './Utc9';
import { Col, Container, Row } from 'react-bootstrap';
import { MatchType } from '../assets/enum/enum';
import { MatchDetail } from './MatchDetail';

export function MatchList(props) {
  let [theme, setTheme] = useState();
  let [backgroundTheme, setBackgroundTheme] = useState();
  let [result, setResult] = useState();
  let [toggle, setToggle] = useState(false);
  let [innerTheme, setInnerTheme] = useState();

  useEffect(() => {
    let matchResult = () => {
      let result = props.match.matchInfo.find(
        (match) => match.ouid === props.ouid
      );
      return result ? result.matchDetail.matchResult : '결과 없음';
    };
    setResult(matchResult());
    let color = result == '승' ? 'blue' : result == '패' ? 'red' : 'gray';
    setTheme(color);
    let innerColor =
      result == '승'
        ? 'lavender'
        : result == '패'
        ? 'lavenderblush'
        : 'whitesmoke';

    setBackgroundTheme(innerColor);
  });

  return (
    <div>
      <div
        className="match-list"
        style={{
          border: `2px solid ${theme}`,
          backgroundColor: `${backgroundTheme}`,
        }}
        onClick={() => {
          setToggle(!toggle);
        }}
      >
        <Row>
          <Col
            xs
            lg="3"
            className="d-flex flex-column align-items-center justify-content-center"
          >
            <div
              style={{
                textAlign: 'center',
                color: `${theme}`,
                fontWeight: 'bold',
              }}
            >
              {MatchType[props.match.matchType]}
            </div>
            <div style={{ textAlign: 'center' }}>
              <Utc9 date={props.match.matchDate} />
            </div>
          </Col>
          <Col xs lg="7" style={{ marginTop: '5px' }}>
            <div>
              <h5>
                {props.match.matchInfo[0].matchDetail.controller ==
                'keyboard' ? (
                  <img src="./img/keyboard.png" className="controller-image" />
                ) : props.match.matchInfo[0].matchDetail.controller ==
                  'gamepad' ? (
                  <img src="./img/pad.png" className="controller-image" />
                ) : (
                  <img
                    src="./img/question-mark.png"
                    className="controller-image"
                  />
                )}{' '}
                {props.match.matchInfo[0].nickname}{' '}
                {props.match.matchInfo[0].shoot.goalTotalDisplay} VS{' '}
                {props.match.matchInfo[1].shoot.goalTotalDisplay}{' '}
                {props.match.matchInfo[1].nickname}{' '}
                {props.match.matchInfo[1].matchDetail.controller ==
                'keyboard' ? (
                  <img src="./img/keyboard.png" className="controller-image" />
                ) : props.match.matchInfo[1].matchDetail.controller ==
                  'gamepad' ? (
                  <img src="./img/pad.png" className="controller-image" />
                ) : (
                  <img
                    src="./img/question-mark.png"
                    className="controller-image"
                  />
                )}
              </h5>
            </div>
            <span>
              매치결과 :{' '}
              <span style={{ color: `${theme}` }}>
                <h6 style={{ display: 'inline', fontWeight: 'bold' }}>
                  {result == '승' ? '승리' : result == '패' ? '패배' : '무승부'}
                </h6>
              </span>
            </span>
            <div style={{ marginTop: '5px' }}>{!toggle ? '▼' : '▲'}</div>
          </Col>
        </Row>
      </div>

      <div>
        <Container>
          <div>{toggle ? <MatchDetail match={props.match} /> : null}</div>
        </Container>
      </div>
    </div>
  );
}
