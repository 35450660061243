import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export async function get(props) {
  let url = BASE_URL + props.extraUrl;

  try {
    let result = await axios.get(url);
    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function post(props) {
  let url = BASE_URL + props.extraUrl;
  try {
    let result = await axios.post(url);
    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function getImg(props) {
  let url = BASE_URL + props.extraUrl;
  try {
    let result = await axios.get(url, { responseType: 'arraybuffer' });

    // ArrayBuffer를 Base64로 변환
    const base64Image = arrayBufferToBase64(result.data);

    // Base64 이미지 데이터를 data URL로 변환 (이미지 형식: PNG)
    const imgUrl = `data:image/png;base64,${base64Image}`;

    return imgUrl; // Base64 형식의 이미지 URL을 반환
  } catch (error) {
    throw error;
  }
}

// ArrayBuffer를 Base64로 변환하는 함수
function arrayBufferToBase64(buffer) {
  let binary = '';
  let bytes = new Uint8Array(buffer);
  let length = bytes.byteLength;

  // Uint8Array를 순회하여 바이너리 문자열을 생성
  for (let i = 0; i < length; i++) {
    binary += String.fromCharCode(bytes[i]);
  }

  // 바이너리 문자열을 Base64로 변환
  return window.btoa(binary);
}
