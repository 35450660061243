import { Card } from 'react-bootstrap';
import { Modal, Row, Col } from 'react-bootstrap/';
import spid from '../assets/spid.json';
import { HomePosition } from '../assets/enum/enum';
import season from '../assets/season.json';
import { useEffect, useState } from 'react';
import { getImg } from '../assets/ajax';

export function PlayerCard(props) {
  let [src, setSrc] = useState('./img/man.png');
  const [playerImage, setPlayerImages] = useState('');
  // JSON 파일을 읽어옵니다
  const jsonData = spid;

  // 'id'를 키로, 'name'을 값으로 갖는 객체로 변환
  const PlayersEnum = Object.freeze(
    jsonData.reduce((acc, player) => {
      acc[player.id] = player.name;
      return acc;
    }, {})
  );

  useEffect(() => {
    let tempSeason = season.find(
      (season) =>
        season.seasonId === parseInt(props.player.spId.toString().slice(0, 3))
    );

    if (tempSeason !== null) {
      setSrc(tempSeason.seasonImg);
    }

    const fetchPlayerImages = async () => {
      let urlImg = await getNetworkImg(
        parseInt(props.player.spId.toString(), 10).toString()
      );

      setPlayerImages(urlImg);
    };

    fetchPlayerImages();
  }, [props.player]);

  return (
    <div>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {PlayersEnum[props.player.spId]}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row
            style={{
              marginBottom: '20px',
              display: 'flex',
              alignItems: 'center', // 세로 가운데 정렬
              justifyContent: 'center', // 가로 가운데 정렬
              textAlign: 'center', // 텍스트 가운데 정렬
            }}
          >
            <Col>
              <img src={playerImage} style={{ width: '120px' }} />
            </Col>
            <Col>
              <p>평 점</p>
              <div style={{ fontWeight: 'bold' }}>
                {props.player.status.spRating}
              </div>
            </Col>
            <Col>
              <p>시 즌</p>
              <img src={src} />
            </Col>
            <Col>
              <p>등 급</p>
              <div className="buildup-container">
                <div
                  className={`buildup buildup-${
                    props.player.spGrade < 3
                      ? 'iron'
                      : props.player.spGrade < 5
                      ? 'bronze'
                      : props.player.spGrade < 7
                      ? 'silver'
                      : 'gold'
                  }`}
                >
                  {props.player.spGrade}
                </div>
              </div>
            </Col>
            <Col>
              <p>포 지 션</p>
              <span>{HomePosition[props.player.spPosition].desc}</span>
            </Col>
          </Row>
          <Row className="playercard-static">
            <div style={{ marginBottom: '7px' }}></div>
            <Col>
              <Row className="playercard-static-row">
                <div className="playercard-static-title">카드</div>
                <div className="playercard-static-content">
                  <div>엘로카드 : {props.player.status.yellowCards}</div>
                  <div>레드카드 : {props.player.status.redCards}</div>
                </div>
              </Row>
              <Row className="playercard-static-row">
                <div className="playercard-static-title">슈팅</div>
                <div className="playercard-static-content">
                  <div>득점수 : {props.player.status.goal}</div>
                  <div>슈팅수 : {props.player.status.shoot}</div>
                  <div>유효슈팅수 : {props.player.status.effectiveShoot}</div>
                  <div>
                    유효슈팅 성공률 :{' '}
                    {props.player.status.shoot !== 0
                      ? Number(
                          (
                            (props.player.status.effectiveShoot /
                              props.player.status.shoot) *
                            100
                          ).toFixed(2)
                        )
                      : 0}
                    %
                  </div>
                </div>
              </Row>
              <Row className="playercard-static-row">
                <div className="playercard-static-title">패싱</div>
                <div className="playercard-static-content">
                  <div>패스 시도 : {props.player.status.passTry}</div>
                  <div>패스 성공 : {props.player.status.passSuccess}</div>
                  <div>
                    패스 성공률 :{' '}
                    {props.player.status.passTry !== 0
                      ? Number(
                          (
                            (props.player.status.passSuccess /
                              props.player.status.passTry) *
                            100
                          ).toFixed(2)
                        )
                      : 0}
                    %
                  </div>
                </div>
              </Row>
              <Row className="playercard-static-row">
                <div className="playercard-static-title">드리블</div>
                <div className="playercard-static-content">
                  <div>드리블 시도 : {props.player.status.dribbleTry}</div>
                  <div>드리블 성공 : {props.player.status.dribbleSuccess}</div>
                  <div>
                    드리블 성공률 :{' '}
                    {props.player.status.dribbleTry !== 0
                      ? Number(
                          (
                            (props.player.status.dribbleSuccess /
                              props.player.status.dribbleTry) *
                            100
                          ).toFixed(2)
                        )
                      : 0}
                    %
                  </div>
                </div>
              </Row>
            </Col>

            <Col>
              <Row className="playercard-static-row">
                <div className="playercard-static-title">볼 소유</div>
                <div className="playercard-static-content">
                  <div>
                    볼 소유 시도 : {props.player.status.ballPossesionTry}
                  </div>
                  <div>
                    볼 소유 성공 : {props.player.status.ballPossesionSuc}
                  </div>
                  <div>
                    볼 소유 성공률 :{' '}
                    {props.player.status.ballPossesionTry !== 0
                      ? Number(
                          (
                            (props.player.status.ballPossesionSuc /
                              props.player.status.ballPossesionTry) *
                            100
                          ).toFixed(2)
                        )
                      : 0}
                    %
                  </div>
                </div>
              </Row>
              <Row className="playercard-static-row">
                <div className="playercard-static-title">공중볼 경합</div>
                <div className="playercard-static-content">
                  <div>공중볼 경합 시도 : {props.player.status.aerialTry}</div>
                  <div>
                    공중볼 경합 성공 : {props.player.status.aerialSuccess}
                  </div>
                  <div>
                    공중볼 경합 성공률 :{' '}
                    {props.player.status.aerialTry !== 0
                      ? Number(
                          (
                            (props.player.status.aerialSuccess /
                              props.player.status.aerialTry) *
                            100
                          ).toFixed(2)
                        )
                      : 0}
                    %
                  </div>
                </div>
              </Row>
              <Row className="playercard-static-row">
                <div className="playercard-static-title">블락</div>
                <div className="playercard-static-content">
                  <div>블락 시도 : {props.player.status.blockTry}</div>
                  <div>블락 성공 : {props.player.status.block}</div>
                  <div>
                    블락 성공률 :{' '}
                    {props.player.status.blockTry !== 0
                      ? Number(
                          (
                            (props.player.status.block /
                              props.player.status.blockTry) *
                            100
                          ).toFixed(2)
                        )
                      : 0}
                    %
                  </div>
                </div>
              </Row>
              <Row className="playercard-static-row">
                <div className="playercard-static-title">태클</div>
                <div className="playercard-static-content">
                  <div>태클 시도 : {props.player.status.tackleTry}</div>
                  <div>태클 성공 : {props.player.status.tackle}</div>
                  <div>
                    태클 성공률 :{' '}
                    {props.player.status.tackleTry !== 0
                      ? Number(
                          (
                            (props.player.status.tackle /
                              props.player.status.tackleTry) *
                            100
                          ).toFixed(2)
                        )
                      : 0}
                    %
                  </div>
                </div>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
}

async function getNetworkImg(spId) {
  let extraUrl = `/meta/img/${spId}`;
  let result = await getImg({ extraUrl });
  return result;
}
