import { useState } from 'react';
import { MaxRankCard } from './MaxRankCard';
import { Container, Row, Col, Nav, Button } from 'react-bootstrap';
import { ReqMatchSearchFilter } from './class/request/ReqMatchSearchFilter';
import { get } from '../assets/ajax';
import { useEffect } from 'react';
import { Loading } from './Loading';
import { Match } from './class/Match';
import { MatchList } from './MatchList';
import matchjson from '../assets/matchjson.json';
import matchjson2 from '../assets/matchjson2.json';
import matchjson3 from '../assets/matchjson3.json';
import MaxRank from '../components/class/Info';
import Chart from './Chart';
import { Shoot } from './Shoot';
import { TopGoal } from './TopGoal';

export function PlayerInfo({ info }) {
  let [loading, setLoading] = useState(true);
  let [offset, setOffset] = useState(0);
  let [matchtype, setMatchtype] = useState(50);
  let [matchList, setMatchList] = useState([]);
  let [reqMatchSearchFilter, setReqMatchSearchFilter] = useState(
    new ReqMatchSearchFilter(info.ouid, matchtype, offset)
  );
  const [rankGame] = useState([50, 52, 214]);
  let [maxRank, setMaxRank] = useState([
    new MaxRank({ matchType: 50, division: 0, achievementDate: null }),
    new MaxRank({ matchType: 52, division: 0, achievementDate: null }),
    new MaxRank({ matchType: 214, division: 0, achievementDate: null }),
  ]);

  useEffect(() => {
    setLoading(true); // 데이터 요청 시작 시 로딩 상태 true로 설정
    let fetchData = async () => {
      // 진짜 로직
      let searchParams = new URLSearchParams(reqMatchSearchFilter).toString(
        reqMatchSearchFilter
      );

      let extraUrl = `/match?${searchParams}`;

      let matchArr = [];
      if (matchList.length !== 0 && matchList[0].matchType === matchtype) {
        matchList.map((match) => {
          matchArr.push(match);
        });
      }

      // 매치 리스트 및 상세 조회
      const matchListData = await get({ extraUrl });

      if (matchListData != null && matchListData.resultCode === '200') {
        for (let i = 0; i < matchListData.result.length; i++) {
          matchArr.push(new Match(matchListData.result[i]));
        }
      }
      setMatchList(matchArr);

      // 최고등급 업데이트
      let realMaxRank = info.maxRank;
      let updatedRanks = maxRank.map((rank) => {
        // realMaxRank에서 matchType이 일치하는 항목 찾기
        let matchedRank = realMaxRank.find(
          (realRank) => realRank.matchType === rank.matchType
        );

        // 일치하는 항목이 있으면 새로운 MaxRank로 업데이트
        if (matchedRank) {
          return new MaxRank({
            matchType: matchedRank.matchType,
            division: matchedRank.division,
            achievementDate: matchedRank.achievementDate,
          });
        }

        // 일치하지 않으면 기존 rank 유지
        return rank;
      });

      // 상태를 한 번에 업데이트
      setMaxRank(updatedRanks);

      // let matchArr = [
      //   new Match(matchjson),
      //   new Match(matchjson2),
      //   new Match(matchjson3),
      //   new Match(matchjson3),
      //   new Match(matchjson3),
      //   new Match(matchjson3),
      //   new Match(matchjson3),
      //   new Match(matchjson3),
      //   new Match(matchjson3),
      //   new Match(matchjson3),
      //   new Match(matchjson3),
      //   new Match(matchjson3),
      //   new Match(matchjson3),
      //   new Match(matchjson3),
      //   new Match(matchjson3),
      // ];
      // setMatchList(matchArr);

      setLoading(false);
    };

    fetchData();
  }, [reqMatchSearchFilter]);

  // matchtype 변경 시 reqMatchSearchFilter 다시 설정
  useEffect(() => {
    setReqMatchSearchFilter(
      new ReqMatchSearchFilter(info.ouid, matchtype, offset)
    );
  }, [info, matchtype, offset]);

  return (
    <div>
      <Container className="container-sm">
        <div className="match-info">
          <Row className="g-3 user-detail">
            <Col xs lg="3">
              <Row>
                <div>
                  <h2 style={{ fontWeight: 'bold' }}>{info.nickname}</h2>
                </div>
              </Row>
              <Row>
                <div>
                  레벨 :{' '}
                  <span style={{ fontWeight: 'bold' }}>{info.level}</span>
                </div>
              </Row>
            </Col>
            {maxRank.map((rank, index) => (
              <Col xs lg="3">
                <MaxRankCard key={index} maxrank={rank} />
              </Col>
            ))}
          </Row>
          <Row className="nav-row">
            <div>
              <Nav variant="pills" defaultActiveKey="link-1">
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-1"
                    onClick={() => {
                      setMatchtype(50);
                    }}
                  >
                    공식모드
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-2"
                    onClick={() => {
                      setMatchtype(40);
                    }}
                  >
                    친선경기
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="link-3"
                    onClick={() => {
                      setMatchtype(52);
                    }}
                  >
                    감독모드
                  </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link eventKey="link-4">거래기록</Nav.Link>
                </Nav.Item> */}
              </Nav>
            </div>
          </Row>
          <Row className="matchlist-statistics justify-content-center align-items-center">
            <Col>
              <Chart matchList={matchList} ouid={info.ouid} />
            </Col>
            <Col>
              <Shoot matchList={matchList} ouid={info.ouid} />
            </Col>
            <Col>
              <TopGoal matchList={matchList} ouid={info.ouid} />
            </Col>
          </Row>
          <Row style={{ width: '100%' }}>
            {loading ? (
              <Loading />
            ) : (
              <div>
                {matchList.length !== 0 ? (
                  <div>
                    {matchList.map((match) => (
                      <MatchList match={match} ouid={info.ouid} />
                    ))}
                    <div className="d-grqid gap-2">
                      <Button
                        variant="primary"
                        size="lg"
                        onClick={() => {
                          setOffset(offset + 1);
                        }}
                      >
                        더보기
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="none-matchlist">
                    <h4>최근 경기 기록이 없습니다.</h4>
                  </div>
                )}
              </div>
            )}
          </Row>
        </div>
      </Container>
    </div>
  );
}
