import { BeatLoader } from 'react-spinners';

export function Loading() {
  return (
    <BeatLoader
      style={{
        display: 'flex', // Flexbox 활성화
        alignItems: 'center', // 세로 중앙 정렬
        justifyContent: 'center', // 가로 중앙 정렬
        height: '80vh', // 부모 요소가 화면 높이 전체를 차지
      }}
    ></BeatLoader>
  );
}
