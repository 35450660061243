import { AwayPosition, HomePosition } from '../assets/enum/enum';
import { SubPlayerIcon } from './SubPlayerIcon';
import spid from '../assets/spid.json';
import { SubName } from './SubName';
import { useEffect, useState, useMemo } from 'react';
import { getImg } from '../assets/ajax';

export function SubPlayerBox(props) {
  const [playerImages, setPlayerImages] = useState({});

  const match = props.matchInfo;

  const PlayersEnum = useMemo(
    () =>
      Object.freeze(
        spid.reduce((acc, player) => {
          acc[player.id] = player.name;
          return acc;
        }, {})
      ),
    [spid]
  );

  useEffect(() => {
    const fetchPlayerImages = async () => {
      const imagePromises = match.player.map(async (player) => {
        if (player.spPosition === 28) {
          const imgUrl = await getNetworkImg(
            // parseInt(player.spId.toString().slice(-6), 10).toString()
            parseInt(player.spId.toString(), 10).toString()
          );
          setPlayerImages((prev) => ({
            ...prev,
            [player.spId]: imgUrl,
          }));
        }
      });
      await Promise.all(imagePromises);
    };

    fetchPlayerImages();
  }, [match]);

  return (
    <div className="sub-container">
      <SubName index={props.index} />
      {match.player.map((player) =>
        player.spPosition == 28 ? (
          <SubPlayerIcon
            key={player.spId}
            src={playerImages[player.spId] || './img/man.png'}
            player={player}
            position={
              props.index
                ? AwayPosition[player.spPosition].desc
                : HomePosition[player.spPosition].desc
            }
            name={
              PlayersEnum[player.spId] != null
                ? PlayersEnum[player.spId].split(' ').pop()
                : null
            }
            borderColor={
              props.index
                ? AwayPosition[player.spPosition].borderColor
                : HomePosition[player.spPosition].borderColor
            }
          />
        ) : null
      )}
    </div>
  );
}

async function getNetworkImg(spId) {
  let extraUrl = `/meta/img/${spId}`;
  let result = await getImg({ extraUrl });
  return result;
}
