import { Container } from 'react-bootstrap';

export function NonePlayer(props) {
  return (
    <Container
      style={{
        display: 'flex', // Flexbox 활성화
        alignItems: 'center', // 세로 중앙 정렬
        justifyContent: 'center', // 가로 중앙 정렬
        height: '70vh', // 부모 요소가 화면 높이 전체를 차지
      }}
    >
      <span>
        <h2>{props.nickname}</h2>
      </span>
      <span>
        <h4 style={{ color: 'gray' }}>은(는) 없는 구단주입니다.</h4>
      </span>
    </Container>
  );
}
