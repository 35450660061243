import { Col, Nav, Row } from 'react-bootstrap';
import { get } from '../assets/ajax.js';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function Footer() {
  const location = useLocation();
  let [today, setToday] = useState(0);
  let [total, setTotal] = useState(0);

  useEffect(() => {
    let getVisitcount = async () => {
      let extraUrl = '/visitcount/today';

      let tempToday = await get({ extraUrl });
      let reuslt = tempToday.result === null ? 0 : tempToday.result.count;
      setToday(reuslt);

      extraUrl = '/visitcount/total';

      let tempTotal = await get({ extraUrl });
      setTotal(tempTotal.result);
    };

    getVisitcount();
  }, [location]);

  return (
    <div className="footer">
      <Row>
        <Col>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              marginLeft: '15px',
            }}
          >
            <div>문의 : xyzkickoff@gmail.com</div>
            <span style={{ cursor: 'pointer' }}>
              <a
                href="https://openapi.nexon.com/ko/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none', color: 'black' }}
              >
                Data based on NEXON Open API
              </a>
            </span>
            <div>
              일부{' '}
              <a
                href="https://pixabay.com/ko/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none', color: 'black' }}
              >
                https://pixabay.com/ko/
              </a>
              와{' '}
              <a
                href="https://www.flaticon.com/kr/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none', color: 'black' }}
              >
                https://www.flaticon.com/kr/
              </a>
              의 이미지를 사용했습니다.
            </div>
          </div>
        </Col>
        <Col style={{ textAlign: 'right' }}>
          <div
            style={{
              border: '1px solid #ccc',
              padding: '10px',
              borderRadius: '8px',
              display: 'inline-block',
              backgroundColor: '#f9f9f9',
              textAlign: 'center',
              marginRight: '15px',
            }}
          >
            <div style={{ fontWeight: 'bold' }}>방문자</div>
            <span style={{ marginRight: '10px' }}>TODAY : {today}</span>
            <span>TOTAL : {total}</span>
          </div>
        </Col>
      </Row>
    </div>
  );
}
export default Footer;
