import './App.css';
import { Container, Navbar } from 'react-bootstrap';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Home from './routes/Home.js';
import Footer from './components/Footer.js';
import Player from './routes/Player.js';

function App() {
  const location = useLocation();
  let nevigate = useNavigate();

  return (
    <div className="App">
      <Navbar className="bg-body-tertiary">
        <Container>
          <Navbar.Brand
            onClick={() => {
              nevigate('/');
            }}
            style={{ cursor: 'pointer', fontWeight: 'bold' }}
          >
            <img
              alt=""
              src="./img/logo.png"
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{' '}
            Kick-Off
          </Navbar.Brand>{' '}
          <div>
            <span style={{ color: 'gray' }}>피파온라인4 전적검색 사이트</span>
          </div>
        </Container>
      </Navbar>

      <div className="main-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:nickname" element={<Player />} />
        </Routes>
      </div>

      <Footer />
    </div>
  );
}

export default App;
