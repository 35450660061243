import { Col, Row, ProgressBar } from 'react-bootstrap';

export function Statistics(props) {
  let home = props.match.matchInfo[0];
  let away = props.match.matchInfo[1];

  return (
    <div style={{ padding: '15px' }}>
      <Row>
        <Col>
          <h4 style={{ fontWeight: '600' }}>{home.nickname}</h4>
        </Col>
        <Col>
          <h4 style={{ fontWeight: '600' }}>VS</h4>
        </Col>
        <Col>
          <h4 style={{ fontWeight: '600' }}>{away.nickname}</h4>
        </Col>
      </Row>
      <hr></hr>
      <Row className="match-statistics">
        <Col className="match-statistics-content">
          <div style={{ marginTop: '10px' }}>
            <ProgressBar
              variant="success"
              now={home.matchDetail.possession}
              label={`${home.matchDetail.possession}%`}
            />
          </div>
          <div>{Number(home.matchDetail.averageRating).toFixed(1)}</div>
          <div>{home.shoot.shootTotal}</div>
          <div>{home.shoot.effectiveShootTotal}</div>
          <div>{home.matchDetail.cornerKick}</div>
          <div>
            {Number(home.matchDetail.dribble * 0.9144).toFixed(1)}
            (m) / {home.matchDetail.dribble}(yd)
          </div>
          <div>
            {Number((home.pass.passSuccess / home.pass.passTry) * 100).toFixed(
              1
            )}
            %
          </div>
          <div>
            {Number(
              (home.defence.blockSuccess / home.defence.blockTry) * 100
            ).toFixed(1)}
            %
          </div>
          <div>
            {Number(
              (home.defence.tackleSuccess / home.defence.tackleTry) * 100
            ).toFixed(1)}
            %
          </div>
          <div>{home.matchDetail.foul}</div>
          <div>{home.matchDetail.yellowCards}</div>
          <div>{home.matchDetail.redCards}</div>
        </Col>
        <Col className="match-statistics-middle">
          <div>점유율</div>
          <div>경기평점</div>
          <div>슈 팅</div>
          <div>유효슈팅</div>
          <div>코너킥</div>
          <div>드리블 거리</div>
          <div>패스 성공률</div>
          <div>블락 성공률</div>
          <div>태클 성공률</div>
          <div>파 울</div>
          <div>옐로우 카드</div>
          <div>레드 카드</div>
        </Col>
        <Col className="match-statistics-content">
          <div style={{ marginTop: '10px' }}>
            <ProgressBar
              variant="success"
              now={away.matchDetail.possession}
              label={`${away.matchDetail.possession}%`}
            />
          </div>
          <div>{Number(away.matchDetail.averageRating).toFixed(1)}</div>
          <div>{away.shoot.shootTotal}</div>
          <div>{away.shoot.effectiveShootTotal}</div>
          <div>{away.matchDetail.cornerKick}</div>
          <div>
            {Number(away.matchDetail.dribble * 0.9144).toFixed(1)}
            (m) / {away.matchDetail.dribble}(yd)
          </div>
          <div>
            {Number((away.pass.passSuccess / away.pass.passTry) * 100).toFixed(
              1
            )}
            %
          </div>
          <div>
            {Number(
              (away.defence.blockSuccess / away.defence.blockTry) * 100
            ).toFixed(1)}
            %
          </div>
          <div>
            {Number(
              (away.defence.tackleSuccess / away.defence.tackleTry) * 100
            ).toFixed(1)}
            %
          </div>
          <div>{away.matchDetail.foul}</div>
          <div>{away.matchDetail.yellowCards}</div>
          <div>{away.matchDetail.redCards}</div>
        </Col>
      </Row>
    </div>
  );
}
