import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function Text() {
  let nevigate = useNavigate();

  return (
    <Form.Control
      size="lg"
      type="text"
      placeholder="구단주명"
      style={{
        width: '600px',
        margin: '0 auto',
      }}
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          nevigate(`/${e.target.value}`);
        }
      }}
    />
  );
}

export default Text;
