import { useState } from 'react';
import { Container, Nav, Col } from 'react-bootstrap';
import { Map } from './Map';
import { SubPlayerBox } from './SubPlayerBox';
import { Statistics } from './Statistics';

export function MatchDetail(props) {
  let [toggle, setToggle] = useState(0);
  let [matchInfo, setMatchInfo] = useState(props.match.matchInfo);

  return (
    <div>
      <div>
        <Nav justify variant="tabs" defaultActiveKey="link-1">
          <Nav.Item>
            <Nav.Link
              eventKey="link-1"
              onClick={() => {
                setToggle(0);
              }}
            >
              포지션
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="link-2"
              onClick={() => {
                setToggle(1);
              }}
            >
              통 계
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
      <div className="match-detail">
        <Container>
          {toggle === 0 ? (
            <Map match={props.match} />
          ) : (
            <Statistics match={props.match} />
          )}
        </Container>
        <Container>
          {toggle == 0
            ? props.match.matchInfo.map((matchInfo, i) => {
                return <SubPlayerBox matchInfo={matchInfo} index={i} />;
              })
            : null}
        </Container>
      </div>
    </div>
  );
}
