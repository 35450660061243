import { useState } from 'react';
import { PlayerCard } from './PlayerCard';

export function PlayerIcon(props) {
  let [modalShow, setModalShow] = useState(false);

  return (
    <div
      className="player-icon-container"
      style={{
        top: props.top,
        left: props.left,
      }}
    >
      <div className="spRating-circle">{props.spRating}</div>
      <img
        className="player-image"
        src={props.src}
        style={{
          border: `3px solid ${props.borderColor}`,
          cursor: 'pointer',
        }}
        onError={(e) => (e.target.src = './img/man.png')}
        onClick={() => {
          setModalShow(true);
        }}
      />
      <PlayerCard
        show={modalShow}
        onHide={() => setModalShow(false)}
        player={props.player}
      />
      <div className="info-box">
        <div className="info-left">{props.position}</div>
        <div className="info-right">{props.name}</div>
      </div>
    </div>
  );
}
