import { Navbar } from 'react-bootstrap';
import Text from '../components/Text';
import { useParams } from 'react-router-dom';
import { get } from '../assets/ajax';
import { useEffect, useState } from 'react';
import { NonePlayer } from '../components/NonePlalyer';
import { PlayerInfo } from '../components/PlayerInfo';
import { Info } from '../components/class/Info';
import { Loading } from '../components/Loading';
import playerinfo from '../assets/playerinfo.json';

function Player() {
  let { nickname } = useParams();
  const [ouid, setOuid] = useState(null);
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true); // 데이터 요청 시작 시 로딩 상태 true로 설정
    let fetchData = async () => {
      // 진짜 로직
      let extraUrl = `/player/${nickname}`;

      // Ouid 조회
      const ouidData = await get({ extraUrl });

      if (ouidData != null && ouidData.resultCode === '200') {
        setOuid(ouidData.result.ouid);

        // ouid 있을경우 개인정보 조회
        extraUrl = `/player/info/${ouidData.result.ouid}`;
        const infoData = await get({ extraUrl });

        if (infoData != null && infoData.resultCode === '200') {
          let temp = new Info(infoData.result);
          setInfo(infoData.result);
        }
      } else if (ouidData != null && ouidData.resultCode === '500') {
        setOuid(null);
        setInfo(null);
      }

      // 목데이터
      // setOuid('c305d74d5466eadc694c352a7c2d96af');
      // setInfo(new Info(playerinfo));

      setLoading(false);
    };
    fetchData();
  }, [nickname]);

  return (
    <div>
      <Navbar bg="dark" data-bs-theme="dark">
        <Text />
      </Navbar>
      {ouid != null && info != null ? (
        loading ? (
          <Loading />
        ) : (
          <PlayerInfo info={info} />
        )
      ) : loading ? (
        <Loading />
      ) : (
        <NonePlayer nickname={nickname} />
      )}
    </div>
  );
}

export default Player;
